import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffSales = () => import('./staff/sales')
const ViewStaffCurrentsAccounts = () => import('./staff/currentsAccounts')
const ViewStaffSettings = () => import('./staff/settings')
const ViewStaffSalesTrashed = () => import('./staff/salesTrashed')
const ViewStaffSalesQuick = () => import('./staff/salesQuick')
const ViewStaffReports = () => import('./staff/reports')

Vue.use(Router)

const router =
  [
    {
      path: '/st/sales',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'SalesStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'sales',
          name: 'SalesStaffSales',
          component: ViewStaffSales,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'sales/generate/remits/:remitsID',
          name: 'SalesStaffSalesGenerateByRemits',
          component: ViewStaffSales,
          beforeEnter: Permisos.IS_PERSONAL,
        },     
        {
          path: 'sales/generate/orders/:ordersID',
          name: 'SalesStaffSalesGenerateByOrders',
          component: ViewStaffSales,
          beforeEnter: Permisos.IS_PERSONAL,
        },                  
        {
          path: 'sales/generate/budget/:budgetID',
          name: 'SalesStaffSalesGenerateByBudget',
          component: ViewStaffSales,
          beforeEnter: Permisos.IS_PERSONAL,
        },         
        {
          path: 'sales/generate/serviceCustomer/:serviceCustomerID',
          name: 'SalesStaffSalesGenerateByServiceCustomer',
          component: ViewStaffSales,
          beforeEnter: Permisos.IS_PERSONAL,
        },                   
        {
          path: 'sales/generate/projects/:projectsID',
          name: 'SalesStaffSalesGenerateByProjects',
          component: ViewStaffSales,
          beforeEnter: Permisos.IS_PERSONAL,
        },                                 
        {
          path: 'CurrentsAccounts',
          name: 'SalesStaffCurrentsAccounts',
          component: ViewStaffCurrentsAccounts,
          beforeEnter: Permisos.IS_PERSONAL,
        },                
        {
          path: 'settings',
          name: 'SalesStaffSettings',
          component: ViewStaffSettings,
          beforeEnter: Permisos.IS_PERSONAL,
        },      
        {
          path: 'salesTrashed',
          name: 'SalesStaffSalesTrashed',
          component: ViewStaffSalesTrashed,
          beforeEnter: Permisos.IS_PERSONAL,
        },    
        {
          path: 'salesQuick',
          name: 'SalesStaffSalesQuick',
          component: ViewStaffSalesQuick,
          beforeEnter: Permisos.IS_PERSONAL,
        },    
        {
          path: 'reports',
          name: 'SalesStaffReports',
          component: ViewStaffReports,
          beforeEnter: Permisos.IS_PERSONAL,
        },              
      ]
    },

    {
      path: '/cu/sales',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/sales',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
