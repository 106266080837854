import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffCrudLeads = () => import('./staff/crudLeads')
const ViewStaffCrudLeadsSellers = () => import('./staff/crudLeadsSellers')
const ViewStaffDetailLeads = () => import('./staff/detailLeads') 
const ViewStaffCrudCategories = () => import('./staff/crudCategories')
const ViewStaffCrudLostMotive = () => import('./staff/crudLostMotive')
const ViewStaffCrudSource = () => import('./staff/crudSource')
const ViewStaffCrudContract = () => import('./staff/crudContract')
const ViewStaffCrudLeadsServices = () => import('./staff/crudLeadsServices')

const ViewPublicApproval = () => import('./public/approval')

Vue.use(Router)

const router =
  [
    {
      path: '/pu/sales-flow-leads/:leadsID/approval',
      component: { render (c) { return c('router-view') }},
      children: [
        {
          path: '',
          name: 'SalesFlowPublicApproval',
          component: ViewPublicApproval,          
        },
      ]
    },

    {
      path: '/st/sales-flow-leads',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'SalesFlowStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },    
        {
          path: 'leads',
          name: 'SalesFlowStaffCrudLeads',
          component: ViewStaffCrudLeads,
          beforeEnter: Permisos.IS_PERSONAL,
        },    
        {
          path: 'leads-sellers',
          name: 'SalesFlowStaffCrudLeadsSellers',
          component: ViewStaffCrudLeadsSellers,
          beforeEnter: Permisos.IS_PERSONAL,
        },    
        {
          path: 'leads-detail/:leadsID/view',
          name: 'SalesFlowStaffDetailLeads',
          component: ViewStaffDetailLeads,
          beforeEnter: Permisos.IS_PERSONAL,
        },                     
        {
          path: 'categories',
          name: 'SalesFlowStaffCrudCategories',
          component: ViewStaffCrudCategories,
          beforeEnter: Permisos.IS_PERSONAL,
        },                             
        {
          path: 'lost-motive',
          name: 'SalesFlowStaffCrudLostMotive',
          component: ViewStaffCrudLostMotive,
          beforeEnter: Permisos.IS_PERSONAL,
        },                             
        {
          path: 'source',
          name: 'SalesFlowStaffCrudSource',
          component: ViewStaffCrudSource,
          beforeEnter: Permisos.IS_PERSONAL,
        },  
        {
          path: 'contract',
          name: 'SalesFlowStaffCrudContract',
          component: ViewStaffCrudContract,
          beforeEnter: Permisos.IS_PERSONAL,
        },    
        {
          path: 'leads-services',
          name: 'SalesFlowStaffCrudLeadsServices',
          component: ViewStaffCrudLeadsServices,
          beforeEnter: Permisos.IS_PERSONAL,
        },                                                             
      ]
    },

    {
      path: '/cu/sales-flow-leads',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/sales-flow-leads',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
